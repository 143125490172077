import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateLoader } from '@ngx-translate/core';
import 'rxjs/add/operator/map';
import { environment } from 'src/environments/environment';
import { getPathName } from 'src/app/core/helpers/window-location';

@Injectable()
export class TranslationService implements TranslateLoader {

    inputPath: string = environment.inputPath;
    apiUrl: string = environment.apiUrl;

    SITE: string;
    currentRoute: string

    arrayRoutes = ['home', 'cookie', 'legal', 'admin', 'home-dashboard', 'home-map', 'survey', 'more-information'];
    arrayRoutesGenerator = ['sites'];

    /** Variable to avoid cache for JSON files */
    readonly httpCacheOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Content-Type':  'application/json'
      })
    }

    constructor(private http: HttpClient) {
      this.getCurrentRoutes();
    }

    /**
     * Get translation rework function
     * @param lang translation's language
     * @returns return a JSON with translations
     */
    getTranslation(lang: string): Observable<any> {
    this.getCurrentRoutes();
    // return this.http.get(`${this.inputPath}SITES/${this.SITE}/DATA/i18n/${lang}.json`, this.httpCacheOptions)
    //   .map((response: JSON) => {
    //     return response;
    //   });
    return this.http.get(`${this.apiUrl}/sites/${this.SITE}/translations?language=${lang}`)
    //return this.http.get(`${this.apiUrl}/sites/${this.SITE}/translations`)  
    .map((response: JSON) => {
        return response;
      });
  }

    /** Get current routes */
    getCurrentRoutes(){
      const pathname = getPathName();
      const arrayPathname = pathname.split('/');
      if(this.arrayRoutes.includes(arrayPathname[1]) && arrayPathname[2]){
        this.SITE = arrayPathname[2].toUpperCase();
      }
      if(this.arrayRoutesGenerator.includes(arrayPathname[2]) && arrayPathname[4]){
        this.SITE = arrayPathname[4].toUpperCase();
      }
    }
}